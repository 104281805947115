<div id="employee-add-container">
  <div class="card">
    <div class="alert alert-info" [innerHTML]="tooltip" *ngIf="('employerPortal_New_Employee'|message|async) as tooltip"></div>

    <form [ngrxFormState]="form" (ngSubmit)="onSubmitClick(form.value)" *ngIf="form$ | async; let form">
      <div class="d-flex flex-wrap">

        <div class="w-100 mb-3">
          <div class="header">
            <h3 class="mt-4 mb-0">Is the employee already a CIRT member?
            </h3>
          </div>
          <app-button-toggle [control]="form.controls.redundancyMember" list="yesNo"></app-button-toggle>

          <app-entry *ngIf="form.value.redundancyMember" title="CIRT Membership Number"
            [control]="form.controls.membershipNumber" class="w-50 pr-1">
          </app-entry>

        </div>
        <app-entry title="First Name" [control]="form.controls.firstName" class="w-50 pr-1">
        </app-entry>

        <app-entry title="Middle Name" [control]="form.controls.middleName" class="w-50 pr-1">
        </app-entry>

        <app-entry title="Last Name" [control]="form.controls.lastName" class="w-50 pr-1">
        </app-entry>

        <app-entry-date title="Date of Birth" [date]="form.controls.dateOfBirth" class="w-50 pr-1"></app-entry-date>


        <app-entry-dropdown title="Gender" list="gender" [control]="form.controls.gender" class="w-50 pr-1">
        </app-entry-dropdown>

        <app-entry title="Occupation" [control]="form.controls.occupation" class="w-50 pr-1">
        </app-entry>

        <app-entry title="Email" entrytype="email" [control]="form.controls.email" class="w-50 pr-1">
        </app-entry>

        <app-entry title="Mobile" [control]="form.controls.mobile" class="w-50 pr-1">
        </app-entry>

        <app-entry-address-accurity title="{{ 'POSTAL_ADDRESS' | translate }}" [address]="form.controls.postalAddress"
          class="w-100">
        </app-entry-address-accurity>

        <app-entry-address-accurity title="{{ 'RESIDENTIAL_ADDRESS' | translate }}"
          [address]="form.controls.residentialAddress" class="w-100">
        </app-entry-address-accurity>

        <app-entry-date title="Employment Start Date" [date]="form.controls.employmentStartDate" class="w-50 pr-1">
        </app-entry-date>


        <app-entry-dropdown title="JETCO Contribution Rate" [control]="form.controls.contributionRate"
          icon="chrome_reader_mode" class="w-50 pr-1" [customList]="contributionRate$">
        </app-entry-dropdown>

        <app-entry title="EBA" [control]="form.controls.eba" class="w-50 pr-1">
        </app-entry>

      </div>
      <div class="components-actions">
        <app-button loading class="float-right w-25" title="Save Details" icon="save" type="submit" [form]="form"
          [isDisabled]="form.isInvalid" (onClick)="onSubmitClick(form.value)">
        </app-button>
      </div>

    </form>
  </div>
</div>
