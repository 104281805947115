import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { createEffect } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { EPEmployeeService } from 'src/app/services/EmployerPortalServices/ep-employee.service';
import { EPContributionService } from 'src/app/services/EmployerPortalServices/ep-contribution.service';

@Injectable()
export class MapFileEditEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    public toastr: ToastrService,
    private router: Router,
    private service: EPEmployeeService,
    private cservice: EPContributionService,
  ) {
  }

  mspToOptions$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.RequestMapToOptionsAction),
    switchMap((action: any) => this.cservice.maptoOptions()),
    map((data: any) => {
      return ActionsList.ResponseMapToOptionsAction({ data: data.data });
    })
  ));

  getById$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.RequestAction),
    switchMap((action: any) => this.service.getEmployee(action.id, action.employmentId)),
    map((data: any) => {
      return ActionsList.ResponseAction({ payload: data.data });
    })
  ));

  submit$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.SubmitRequestAction),
    switchMap((action: any) => this.cservice.uploadfilesubmit(action.data)),
    map((data: any) => {
      this.service.showOk(data.message);
      return ActionsList.SubmitResponseAction({ data: data.data });
    })
  ));

  validateUploadFileRequestAction$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.ValidateUploadFileRequestAction),
    switchMap((action: any) => this.cservice.validateUploadFileRequest(action.data)),
    map((data: any) => {
      this.service.showOk(data.message);
      return ActionsList.ValidateUploadFileResponseAction({ data: data.data });
    })
  ));

  submitUploadFileRequestAction$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.SubmitUploadFileRequestAction),
    switchMap((action: any) => this.cservice.submitUploadFileRequest(action.data)),
    map((data: any) => {
      this.service.showOk(data.message);
      return ActionsList.SubmitUploadFileResponseAction({ data: data.data });
    })
  ));

  getTemplate$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.RequestTemplateAction),
    switchMap((action: any) => this.cservice.getTemplate()),
    map((data: any) => {
      const blob = new Blob([atob(data.fileContents)], { type: 'text/csv' }); // you can change the type
      const url= window.URL.createObjectURL(blob);
      var anchor = document.createElement("a");
      anchor.download = "mapping.csv";
      anchor.href = url;
      anchor.click();

      return ActionsList.ResponseTemplateAction({ data: data.data });
    })
  ));
}
