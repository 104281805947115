import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { createEffect } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { EPEmployeeService } from 'src/app/services/EmployerPortalServices/ep-employee.service';

@Injectable()
export class EditEmployeeEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    public toastr: ToastrService,
    private router: Router,
    private service: EPEmployeeService) {
  }

  getById$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.RequestAction),
    switchMap((action: any) => this.service.getEmployee(action.id, action.employmentId)),
    map((data: any) => {
      return ActionsList.ResponseAction({ payload: data.data });
    })
  ));

  submit$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.SubmitRequestAction),
    switchMap((action: any) => this.service.updateEmployeeDetails(action.payload)),
    map((data: any) => {
      this.toastr.success(data.message);
      return ActionsList.SubmitResponseAction({ payload: data });
    })
  ));
}
