import { createReducer, on, Action } from '@ngrx/store';
import {
  createFormGroupState, onNgrxForms, updateGroup, wrapReducerWithFormStateUpdate,
  onNgrxFormsAction, SetValueAction, setValue, disable, enable, validate
} from 'ngrx-forms';
import { IContributionEditState } from './state';
import { ColumnSortAction, LoadUploadFileAction, RequestAction, ResetFormAction, ResponseAction, ResponseCloneAction, SaveResponseAction, SubmitResponseAction, SumTotalsAction } from './actions';
import { ContributionDetailModel, ContributionModel } from 'src/app/model/contribution.model';
import { greaterThan, required, requiredTrue } from 'ngrx-forms/validation';
import { Helper } from '@ifaa-components/ui-components';

export const formName = 'ContributionEditForm';
export const formEditEmployeeName = 'ContributionEditEmployeeForm';
var helper = new Helper();
export const state: IContributionEditState = {
  form: createFormGroupState(formName, new ContributionModel()),
  lastModifiedTime: null,
  isEditMode: true,
  totalUpdate: false,
  response: null,
  columnSort: { column: 'endDate', order: 'DESC' }
};

export const validateEditContributionForm = updateGroup<ContributionModel>({
  startDate: validate(required),
  contributionTotal: validate(greaterThan(0)),
  youAcknowledge: (state, parentState) => {

    if (parentState.value.isClone) {
      state = validate(state, requiredTrue);
    }
    else {
      // state = validate(state, null);
    }
    return state;

  }
});

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    if (!action.controlId.startsWith("ContributionEditForm.contributionDetails"))
      return state;

    const actions = action.controlId.split(".");
    if (actions.length !== 4)
      return state;

    return {
      ...state,
      totalUpdate: true
    }
  }),
  on(SumTotalsAction, (state) => {
    const newTotals = state.form.value.contributionDetails.reduce((prev, curr) => {
      const contributionTotals = {
        donationTotal: prev.donationTotal + curr.donationAmount,
        severanceTotal: prev.severanceTotal + curr.severanceAmount,
        trainingTotal: prev.trainingTotal + curr.trainingAmount,
        total: 0
      };

      contributionTotals.total = contributionTotals.donationTotal + contributionTotals.severanceTotal + contributionTotals.trainingTotal;
      return contributionTotals;
    }, {
      severanceTotal: 0,
      donationTotal: 0,
      trainingTotal: 0,
      total: 0
    });

    let initial = state.form.value;
    let updatedForm = setValue({
      ...initial, severanceTotal: newTotals.severanceTotal, donationTotal: newTotals.donationTotal,
      trainingTotal: newTotals.trainingTotal, contributionTotal: newTotals.total
    })(state.form);

    return {
      ...state,
      totalUpdate: false,
      form: updatedForm
    };
  }),
  on(ResetFormAction, (state) => {
    return {
      ...state,
      form: setValue(new ContributionModel())(state.form),
      isEditMode: true,
      lastModifiedTime: null,
    };
  }),
  on(RequestAction, (state, { id }) => {
    let form = setValue(new ContributionModel())(state.form);
    return {
      ...state,
      form: form,
      isEditMode: true,
      lastModifiedTime: null
    };
  }),
  on(ResponseAction, (state, { payload }) => {
    var obj = Object.assign(new ContributionModel(), payload);

    if (!obj.startDate)
      obj.startDate = `${new Date().getFullYear()}-${new Date().getMonth() + 1}-01`;
    else
      obj.startDate = `${new Date(obj.startDate).getFullYear()}-${new Date(obj.startDate).getMonth() + 1}-01`;

    var list = obj.contributionDetails.map(x => {
      return Object.assign(new ContributionDetailModel(), x);
    })

    obj.contributionDetails = list;


    var newForm = createFormGroupState(formName, obj);

    return {
      ...state,
      form: payload.status < 2 ? enable(newForm) : disable(newForm),
      isEditMode: payload.status < 2,
    };

  }),
  on(LoadUploadFileAction, (state, { payload }) => {
    var obj = Object.assign(new ContributionModel(), payload);

    var list = obj.contributionDetails.map(x => {
      return Object.assign(new ContributionDetailModel(), x);
    });

    obj.contributionDetails = list;


    var newForm = createFormGroupState(formName, obj);

    return {
      ...state,
      form: payload.status < 2 ? enable(newForm) : disable(newForm),
      isEditMode: payload.status < 2,
    };

  }),
  on(ResponseCloneAction, (state, { payload }) => {
    var obj = Object.assign(new ContributionModel(), payload);

    var list = obj.contributionDetails.map(x => {
      return Object.assign(new ContributionDetailModel(), x);
    })

    obj.contributionDetails = list;
    let newState = {
      ...state,
      form: createFormGroupState(formName, Object.assign(new ContributionModel(), obj)),
    };

    return {
      ...state,
      form: payload.status < 2 ? enable(newState.form) : disable(newState.form),
      isEditMode: true,
      lastModifiedTime: null
    };

  }),
  on(SaveResponseAction, (state, { payload }) => {
    var clone = helper.clone(state.form.value);
    clone.contributionId = payload.data;

    return {
      ...state,
      form: setValue(clone)(state.form),
    };
  }),
  on(SubmitResponseAction, (state, { payload }) => {
    return {
      ...state,
      lastModifiedTime: new Date(),
      response: payload.data
    };
  }),
  on(ColumnSortAction, (state, { column }) => {
    const formStateDetails = state.form.value.contributionDetails;

    let stateUpdate;
    let newSortState;

    // if column is already sorted by ascending, set to descending
    if (state.columnSort.column === column && state.columnSort.order === "ASC") {
      stateUpdate = [...formStateDetails].sort((a, b) => {
        if (b[column] > a[column]) {
          return 1;
        } else if (b[column] < a[column]) {
          return -1;
        } else {
          return 0
        }

      });
      newSortState = { column: column, order: "DESC" };

      // if column is already sorted by ascending, set to descending
    } else if (state.columnSort.column === column && state.columnSort.order === "DESC") {
      stateUpdate = [...formStateDetails].sort((a, b) => {
        if (a[column] > b[column]) {
          return 1;
        } else if (a[column] < b[column]) {
          return -1;
        } else {
          return 0
        }
      });

      newSortState = { column: column, order: "ASC" };
    }

    // if a new column is being selected for sorting, set that column descending first
    else if (state.columnSort.column !== column) {
      stateUpdate = [...formStateDetails].sort((a, b) => {
        if (a[column] > b[column]) {
          return 1;
        } else if (a[column] < b[column]) {
          return -1;
        } else {
          return 0
        }
      });

      newSortState = { column: column, order: "ASC" };
    }

    let initial = state.form.value
    let updated = setValue({ ...initial, contributionDetails: stateUpdate })(state.form);

    return {
      ...state,
      form: updated,
      columnSort: newSortState
    }

  })
);

const editContributionReducerFormState = wrapReducerWithFormStateUpdate(
  reducer,
  s => s.form,
  validateEditContributionForm,
);

export function contributionEditReducer(state: any | undefined, action: Action) {
  return editContributionReducerFormState(state, action);
}
